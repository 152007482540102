/* :root {
  --card-height: 300px;
  --card-width: calc(var(--card-height) / 1.5);
}
* {
  box-sizing: border-box;
}
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #191c29;
} */
.card {
  height: 500px;
  width: 300%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 36px;
  perspective: 2500px;
  margin: 0 0;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper {
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.card:hover .wrapper {
  transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
  box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
}

.wrapper::before,
.wrapper::after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 80px;
  transition: all 0.5s;
  position: absolute;
  left: 0;
  border-radius: 0px 0px 0 33px;
}
.wrapper::before {
  top: 0;
  height: 100%;
  background-image: linear-gradient(
    to top,
    transparent 46%,
    rgba(12, 13, 19, 0.5) 68%,
    rgba(12, 13, 19) 97%
  );
  /* border-radius: 0px 0px 33px 33px; */
}
.wrapper::after {
  bottom: 0;
  opacity: 1;
  background-image: linear-gradient(
    to bottom,
    transparent 46%,
    rgba(12, 13, 19, 0.5) 68%,
    rgba(12, 13, 19) 97%
  );
}

.card:hover .wrapper::before,
.wrapper::after {
  opacity: 1;
}

.card:hover .wrapper::after {
  height: 120px;
}
.title {
  width: 100%;
  transition: transform 0.5s;
}
.card:hover .title {
  transform: translate3d(0%, -50px, 100px);
}

.character {
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  z-index: -1;
}

.card:hover .character {
  opacity: 1;
  transform: translate3d(0%, -30%, 100px);
}

/* AI Form Styles */
.ai-form-container {
  text-align: center;
  margin: 4rem auto;
  padding: 3rem;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 255, 0.1);
  transition: transform 0.3s ease;
}

.ai-title {
  font-size: 2.8rem;
  margin-bottom: 2.5rem;
  background: linear-gradient(45deg, #00f2fe, #4facfe);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.ai-input-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
}

.ai-select, .ai-input {
  width: 100%;
  margin: 10px 0;
  padding: 15px 20px;
  background: rgba(255, 255, 255, 0.05);
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: #fff;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.ai-textarea {
  width: 100%;
  min-height: 150px;
  margin: 20px 0;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: #fff;
  font-size: 1.1rem;
  resize: vertical;
  font-family: inherit;
}

.ai-select:focus,
.ai-input:focus,
.ai-textarea:focus {
  outline: none;
  border-color: #4facfe;
  box-shadow: 0 0 20px rgba(79, 172, 254, 0.3);
  background: rgba(255, 255, 255, 0.08);
}

.ai-submit-button {
  margin-top: 25px;
  padding: 15px 40px;
  background: linear-gradient(45deg, #00f2fe, #4facfe);
  border: none;
  border-radius: 30px;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.ai-submit-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 7px 20px rgba(79, 172, 254, 0.4);
}

.ai-submit-button:active {
  transform: translateY(-1px);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .ai-form-container {
    padding: 2rem;
    margin: 2rem auto;
  }

  .ai-title {
    font-size: 2rem;
  }

  .ai-select,
  .ai-input,
  .ai-textarea {
    font-size: 1rem;
    padding: 12px 15px;
  }

  .ai-submit-button {
    padding: 12px 30px;
    font-size: 1rem;
  }
}

.chat-input-form {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.75rem;
  background-color: #f8f9fa;
  width: 100%;
  transition: colors 150ms;
  border: 1px solid #e1e1e1;
  max-width: 800px;
  margin: 2rem auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.chat-input-form:focus-within {
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.chat-input-wrapper {
  position: relative;
}

.chat-input {
  width: 100%;
  border-radius: 0.375rem;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  line-height: 1.5;
  background-color: #ffffff;
  color: transparent;
  caret-color: #666666;
  resize: none;
  height: 52px;
  outline: none;
  cursor: default;
  user-select: none;
  border: 1px solid #e1e1e1;
}

.inline-inputs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  pointer-events: none;
  white-space: nowrap;
  z-index: 2;
  gap: 0.5rem;
}

.static-text {
  color: #666666;
  font-size: 1.1rem;
  pointer-events: none;
  margin: 0 0.35rem;
}

.inline-select,
.inline-input {
  pointer-events: auto;
  background: transparent;
  border: none;
  color: #4facfe;
  font-size: 1.1rem;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.inline-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 1.5em;
  margin: 0 0.35rem;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%234facfe' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E") no-repeat right center;
}

.inline-input {
  min-width: 120px;
  max-width: 200px;
  margin: 0 0.35rem;
}

.inline-input::placeholder {
  color: #4facfe;
  opacity: 0.7;
}

.inline-select:focus,
.inline-input:focus {
  border-bottom: 1px solid #4facfe;
}

.inline-select option {
  background-color: #ffffff;
  color: #333333;
  padding: 8px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .inline-inputs {
    flex-wrap: wrap;
    height: auto;
    min-height: 100%;
    justify-content: flex-start;
    padding: 0.75rem;
  }
  
  .chat-input {
    height: auto;
    min-height: 78px;
  }
}

.input-controls {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.control-button {
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  border-radius: 9999px;
  background-color: transparent;
  border: 1px solid rgb(63 63 70);
  color: rgb(244 244 245);
  display: flex;
  align-items: center;
  gap: 0.25rem;
  transition: background-color 150ms;
}

.control-button:hover {
  background-color: rgba(244, 244, 245, 0.1);
}

.submit-button {
  margin-left: auto;
  color: rgb(244 244 245);
  opacity: 0.5;
  cursor: not-allowed;
  transition: opacity 150ms;
}

.submit-button:not(:disabled) {
  opacity: 1;
  cursor: pointer;
}

/* Scrollbar styles for the textarea */
.chat-input::-webkit-scrollbar {
  width: 6px;
}

.chat-input::-webkit-scrollbar-thumb {
  background: #636575;
}

.chat-input::-webkit-scrollbar-track {
  background: transparent;
}

/* Update existing styles */
.control-button.select-type {
  min-width: 150px;
  background: transparent;
  color: rgb(244 244 245);
  cursor: pointer;
}

.control-button.store-name {
  min-width: 200px;
  background: transparent;
  color: rgb(244 244 245);
}

.control-button.store-name::placeholder {
  color: rgb(161 161 170);
}

/* Make sure options are readable */
.control-button.select-type option {
  background-color: rgb(39 39 42);
  color: rgb(244 244 245);
  padding: 8px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .control-button.select-type,
  .control-button.store-name {
    min-width: 100%;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.generate-button {
  background: linear-gradient(45deg, #00f2fe, #4facfe);
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px 40px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  opacity: 0.5;
  position: relative;
  overflow: hidden;
  min-width: 200px;
}

.generate-button:not(:disabled) {
  opacity: 1;
  box-shadow: 0 4px 15px rgba(79, 172, 254, 0.4);
}

.generate-button:not(:disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 7px 25px rgba(79, 172, 254, 0.6);
}

.generate-button:not(:disabled):active {
  transform: translateY(1px);
}

.generate-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: 0.5s;
}

.generate-button:hover::before {
  left: 100%;
}
