@keyframes shake {
  25% {
    transform: rotate(calc(var(--angle) * -1));
  }

  50% {
    transform: rotate(var(--angle));
  }

  100% {
    transform: rotate(0deg);
  }
}

/* align-content: center; */

.display {
  display: flex;
  gap: 0.8em;
  text-align: center;
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  flex-wrap: wrap;

  /* width: 280px;
  height: 460px; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  background: #fff0;
  transition: all 0.5s ease;
  /* cursor: pointer; */
  /* user-select: none; */
  z-index: 10;
  overflow: hidden;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 160px;
}

.HOVER {
  --width: 100%;
  --time: 0.7s;

  position: relative;
  display: inline-block;
  height: 15em;
  padding: 50px;
  width: 25%;
  text-align: center;
  color: white;
  background: #ffffff;

  box-shadow: 0 0 10px #cdd5eb;

  transition: all 0.5s ease;
  /* cursor: pointer; */
  /* user-select: auto; */
  z-index: 10;
  overflow: hidden;
  border: 5px solid;
  border-color: #eee;
  border-radius: 5px;
}

.Main_title {
  display: flex;
  position: relative;
  color: #182227;
  font-size: 40px;
  font-weight: 100;
  /* font-family: "Lato", sans-serif; */
  margin-top: 200px;
  align-items: center;
  justify-content: center;
  width: 100%;
  line-height: 1.1;
}

.img-circle {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  object-fit: cover;
}
.img-fluid {
  display: block;
  max-width: 100%;
  height: auto;
}
.img {
  margin: 10px;
  max-width: 100%;
}
.Brand_img {
  margin: 10px;
  max-width: 100%;
}

.HOVER text {
  position: relative;
  z-index: 5;
  /* text-align: center; */
  transition: color var(--time);
}

.HOVER:hover text {
  color: #222;
}

.HOVER span {
  position: absolute;
  display: block;
  content: "";
  z-index: 0;
  width: 0;
  height: 0;

  border-radius: 100%;
  background: #ffa4fa;

  transform: translate(-50%, -50%);
  transition: width var(--time), padding-top var(--time);
}

.HOVER:hover span {
  width: calc(var(--width) * 3.25);
  padding-top: calc(var(--width) * 3.25);
}

.HOVER.FLASH:hover text {
  color: white;
}

.HOVER.FLASH span {
  background: #ff3b3b;
}

.animated {
  --angle: 10deg;
  animation: shake 0.3s;
}

.link {
  position: absolute;
  right: 10px;
  bottom: 10px;

  font-size: 1rem;
}

@media screen and (max-width: 960px) {
  .display {
    display: grid;
  }
  .HOVER {
    width: 100%;
  }
}
