@import url('https://fonts.googleapis.com/css2?family=Gugi&display=swap');

/* Background data (Original source: https://subtlepatterns.com/grid-me/) */
$bg-url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC";
$bg-width: 50px;
$bg-height: 50px;

/* Animations */
@-webkit-keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}
@-moz-keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}
@-o-keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}
@keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}

@-webkit-keyframes bg-scrolling {
  0% {
    background-position: $bg-width $bg-height;
  }
}
@-moz-keyframes bg-scrolling {
  0% {
    background-position: $bg-width $bg-height;
  }
}
@-o-keyframes bg-scrolling {
  0% {
    background-position: $bg-width $bg-height;
  }
}
@keyframes bg-scrolling {
  0% {
    background-position: $bg-width $bg-height;
  }
}

/* Main styles */
.Vc {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-bottom: 7.5rem;
  color: #000000;

  // font: 400 16px/1.5 "Space Grotesk", sans-serif;
  text-align: center;
  /* img size is 50x50 */
  background: url($bg-url) repeat 0 0;
  -webkit-animation: bg-scrolling-reverse 0.92s infinite; /* Safari 4+ */
  -moz-animation: bg-scrolling-reverse 0.92s infinite; /* Fx 5+ */
  -o-animation: bg-scrolling-reverse 0.92s infinite; /* Opera 12+ */
  animation: bg-scrolling-reverse 0.92s infinite; /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
  

  &::before {
     content: "Launch Your ";
    font-size: 7rem;
    font-weight: 600; /* Updated from 50 */
    font-style: normal;
    font-family: 'Gugi', sans-serif;
    color: #111827;
    
  }
  
  &::after {
    content: "In seconds.";
    font-size: 4rem;
    font-weight: 500; /* Updated from 50 */
    font-style: normal;
    font-family: monospace;
    font-family: 'Gugi', sans-serif;
  }
}


.text-container {
  display: flex;
  justify-content: center;
  font-size: 6rem;
  font-weight: 600;
  font-style: normal;
  font-family: 'Gugi', sans-serif;
  color: #111827;
  animation: textChange 6s ease-out infinite;
 // font-family: "Recoleta", sans-serif; /* Use the Recoleta font here */


  &::after {
    
    font-size: 6rem;
    font-weight: 500;
    font-style: normal;
    font-family: monospace;
  }

  .ai-word {
    color: #ff6600; /* Change to your desired color for AI */
    font-weight: bold; /* Optionally, make it bold */
  }
}
@media screen and (max-width: 760px) {
  .Vc::before,.Vc::after,.text-container {
    font-size: 3rem;
  }
  // .Vc::before {
  //   font-size: 3rem;
  // }
}
/* Additional styles for animation (optional) */
@keyframes textChange {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.text-container::before {
  animation: textChange 2s linear infinite; /* Adjust the duration as needed */
 // content: "Launch Your Website ";
 
}
.custom-button {
  border-radius: 24px;
  background: #fff; /* Specify your desired background color */
  white-space: nowrap;
  padding: 10px 20px; /* Adjust padding as needed */
  color: #1d6086;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  border: 1px solid #E7131A; /* Adjust border color as needed */
  cursor: pointer;
  text-decoration: none;
  margin-top: 20px;

  &:hover {
    transition: all 0.3s ease-out;
    background-color: #fff; /* Specify hover background color */
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.35);
  }
}
.arrow{
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: #000;
  transition: transform 0.2s, box-shadow 0.2s, border 0.2s;
  animation: floatIcon 1s infinite; // Floating animation
}
.social-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;

  
  a {
    color: #fff;
    margin: 5%;
    font-size: 60px; // Adjust the size as needed
    padding: 15px 15px; // Adjust the spacing between icons
    text-decoration: none;
    //background: linear-gradient(90deg, #111727, #08012d); // Pink gradient background
    padding: 20px; // Add some padding to the container
    border-radius: 20px; 
  

    border: 3px solid transparent; // Add transparent border
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //box-shadow: 0 0 10px #00a0f7;

    &:hover {
      transform: scale(1.1); // Slight scale-up on hover
      box-shadow: 0 0 10px #93989a; // Add a blue shadow on hover
      background: linear-gradient(90deg, #e6ebf6, #f6f6f6);
      color: #000000;
     
    }

    // &:focus {
    //   outline: none; // Remove the default focus outline
    //   box-shadow: 0 0 10px #00a0f7; // Add a blue shadow when focused
    //   border-color: #00a0f7; // Change border color when focused
    // }
  }
}

@keyframes floatIcon {
  0%, 100% {
    transform: translateY(-25%);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,.2,1);// Adjust the floating distance
  }
}









