/*------------------------------------------------*/
/* body {
    display: flex;
    justify-content: center;
    padding: 5%;
    background: url("https://i.pinimg.com/originals/34/ec/b5/34ecb5082a381e120f5f9a7a74f5f9da.jpg") no-repeat;
  } */

.iphone-x {
  position: relative;
  z-index: 1;
  height: 429px;
  width: 220px;
}
@media screen and (max-width: 768px) {
  .iphone-x {
    display: none;
  }
}

.iphone-x *,
.iphone-x *::before,
.iphone-x *::after {
  box-sizing: border-box;
  display: block;
}
.iphone-x .bazzel {
  background: transparent;
  border-radius: 54px;
  box-shadow: inset 0 0 2px 2px #c8cacb, inset 0 0 0 6px #e2e3e4;
  height: 425px;
  padding: 15px 22px;
  width: 220px;
  z-index: 1;
}
.iphone-x .screen {
  background: transparent;
  background-position: center center;
  background-size: cover;
  border-radius: 32px;
  height: 390px;
  position: relative;
  width: 191px;
  margin-left: -8px;
}
.iphone-x .line::after,
.iphone-x .line::before {
  content: "";
  position: absolute;
  border: solid rgba(68, 68, 68, 0.25);
  border-width: 0 6px;
  height: 5px;
  left: 0;
  width: 100%;
  z-index: 9;
}
.iphone-x .line::after {
  top: 68px;
}
.iphone-x .line::before {
  bottom: 68px;
}
.iphone-x .header {
  background: #fff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  height: 20px;
  left: 50%;
  margin-left: -82px;
  position: absolute;
  top: 15px;
  width: 164px;
  z-index: 199;
}
.iphone-x .sensor-1::after,
.iphone-x .sensor-1::before {
  content: "";
  position: absolute;
}
.iphone-x .sensor-1::after {
  background: #444;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  left: 1%;
  margin-left: 10px;
  top: 1px;
}
.iphone-x .sensor-1::before {
  background: #444;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  left: 10%;
  margin-left: 20px;
  top: 1px;
}
.iphone-x .sensor-2::after,
.iphone-x .sensor-2::before {
  content: "";
  position: absolute;
}
.iphone-x .sensor-2::before {
  background: #444;
  border-radius: 2.5px;
  height: 5px;
  width: 40px;
  left: 50%;
  margin-left: -20px;
  top: 4px;
}
.iphone-x .sensor-3::after,
.iphone-x .sensor-3::before {
  content: "";
  position: absolute;
}
.iphone-x .sensor-3::before {
  background: #444;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  left: 50%;
  margin-left: 35px;
  top: 1px;
}
.iphone-x .sensor-3::after {
  background: #444;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  left: 65%;
  margin-left: 33px;
  top: -1px;
}
.iphone-x .volume-button {
  background: #c8cacb;
  height: 26px;
  left: -2px;
  position: absolute;
  top: 92px;
  width: 3px;
}
.iphone-x .volume-button::after,
.iphone-x .volume-button::before {
  content: "";
  position: absolute;
  background: #c8cacb;
  height: 50px;
  left: 0;
  width: 3px;
}
.iphone-x .volume-button::after {
  top: 48px;
}
.iphone-x .volume-button::before {
  top: 112px;
}
.iphone-x .power-button {
  background: #c8cacb;
  height: 80px;
  right: -2px;
  position: absolute;
  top: 160px;
  width: 3px;
}
