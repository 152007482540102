$base-duration: 300ms;

// Colors
$color-1: #e91e63;
$color-2: #03a9f4;
$color-3: #2ecc71;
$white: rgb(0, 0, 0);

// Breakpoints
$sm: new-breakpoint(min-width 320px);
$med: new-breakpoint(min-width 768px);
$lg: new-breakpoint(min-width 1024px);

.div1 {
  font-size: 50px;
  color: black;
  margin-bottom: 10px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  max-width: 100%;

  &:hover {
    &:before,
    &:after {
      position: absolute;
      content: "Simulate Your Card";
      // transform: translate(-10, -10);
      z-index: -1;
      width: 100%;
    }

    &:before {
      top: 55.5%;
      left: 50.5%;
      color: rgba($color-1, 0.8);
      animation: distort1 $base-duration linear infinite;
    }

    &:after {
      top: 50.5%;
      left: 50%;
      color: rgba($color-2, 0.8);
      animation: distort2 $base-duration linear infinite;
    }
  }

  @keyframes distort1 {
    0% {
      top: 0.5%;
      left: 0.5%;
    }
    12.5% {
      top: 0.5%;
      left: 0.5%;
    }
    25% {
      top: 0.5%;
      left: 0.5%;
    }
    37.5% {
      top: 0%;
      left: 0.5%;
    }
    50% {
      top: 0.5%;
      left: 0.5%;
    }
    62.5% {
      top: 0.5%;
      left: 0%;
    }
    75% {
      top: 0.5%;
      left: 0.5%;
    }
    87.5% {
      top: 0%;
      left: 0.5%;
    }
    100% {
      top: 0.5%;
      left: 0.5%;
    }
  }

  @keyframes distort2 {
    0% {
      top: 0.5%;
      left: 0.5%;
    }
    12.5% {
      top: 0%;
      left: 0.5%;
    }
    25% {
      top: 0.5%;
      left: 0.5%;
    }
    37.5% {
      top: 0.5%;
      left: 0%;
    }
    50% {
      top: 0.5%;
      left: 0.5%;
    }
    62.5% {
      top: 0%;
      left: 0.5%;
    }
    75% {
      top: 0.5%;
      left: 0.5%;
    }
    87.5% {
      top: 0.5%;
      left: 0%;
    }
    100% {
      top: 0.5%;
      left: 0.5%;
    }
  }
}
